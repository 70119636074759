<template>
  <div>
    <div class="container py-3">
      <header>
        <div class="pricing-header p-3 pb-md-4 mx-auto text-center">
          <h1 class="">Subscription Plans</h1>
          <p class="fs-5 text-muted">
            <span class="text-info">Get one month free! By renewing your subscription for 12 months. </span>
            Upgrade your subscription to manage more employees.
          </p>
          <div v-if="subscription.package.name === 'Trial'" role="alert" class="alert alert-info">
            <div class="content text-center">
              You are currently on the Trial Version from {{ formatDate(subscription.from) }}. Due {{ formatDate(subscription.to) }}
            </div>
          </div>
        </div>
      </header>

      <main>
        <div v-if="loading" class="text-center">
          <Spinner spinner-class="mb-1" />
        </div>
        <div v-else class="row row-cols-1 row-cols-xl-4 row-cols-md-2 mb-3 text-center">
          <div class="col" v-for="pack in packages" :key="pack.id">
            <div class="card mb-4 rounded-3 shadow-sm" :class="pack.name === 'Enterprise' ? 'border-primary' : ''">
              <div class="card-header py-3" :class="pack.name === 'Enterprise' ? 'text-white bg-primary border-primary' : ''">
                <h4 class="my-0 fw-normal">{{ pack.name }}</h4>
              </div>
              <div class="card-body">
                <h2 v-if="pack.name !== 'Free'" class="card-title pricing-card-title">KSh.{{ pack.price }}<small class="text-muted fw-light">/mo</small></h2>
                <h5 v-if="pack.name !== 'Free'" class="text-muted fw-light">Ksh. {{ pack.price * 11 }}/yr <span class="h6 text-danger">save 9%</span></h5>
                <ul class="list-unstyled mt-3 mb-4">
                  <li class="h4"><strong>{{ pack.employees === 1000 ? 'Unlimited' : pack.employees }}</strong> employees</li>
                  <li><i class="fas fa-check text-success"></i> Payroll management</li>
                  <li><i class="fas fa-check text-success"></i> Employee management</li>
                  <li><i class="fas fa-check text-success"></i> Secure payslips</li>
                  <li><i class="fas fa-check text-success"></i> Automated payslip emailing</li>
                  <li><i class="fas fa-check text-success"></i> Loan Deduction</li>
                  <li><i class="fas fa-check text-success"></i> All By-products(Including Banks)</li>
                  <li><i class="fas fa-check text-success"></i> Multiple payroll runs</li>
                  <li><i class="fas fa-check text-success"></i> Annual statistics</li>
                  <li><i class="fas fa-check text-success"></i> Employee statistics</li>
                  <li><i class="fas fa-check text-success"></i> Audit trail</li>
                  <li><i class="fas fa-check text-success"></i> 24/7 support</li>
                </ul>
                <button v-if="pack.name !== 'Free'" type="button" class="w-100 btn btn-lg btn-primary" :class="disableUpgradeBtn(pack)" @click="selectPackage(pack); upgradeModal = true">Upgrade</button>
                <button v-else type="button" class="w-100 btn btn-lg btn-primary" :class="disableUpgradeBtn(pack)" @click="freeSubscription()">Free</button>
                <h4 class="text-muted fw-light mt-3" :class="subscription.package.name === pack.name ? '' : 'd-none'">
                  <hr class="border-1 border-info border-opacity-75 mt-3" />
                  Current plan
                </h4>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>

    <!-- Upgrade Subscription Modal -->
    <Modal :open="upgradeModal" @closed="upgradeModal = false" dialog-size="modal-md">
      <div class="modal-header">
        <h5 class="modal-title m-0">Upgrade Subscription</h5>
        <button @click="upgradeModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <div role="alert" class="alert alert-info">
          <div class="content text-center">
            Get one month free! By subscribing for 12 months.
          </div>
        </div>
        <br>
        <span style="display:block; text-align: center">
          <img src="https://intasend-prod-static.s3.amazonaws.com/img/trust-badges/intasend-trust-badge-with-mpesa-hr-dark.png" width="100%" alt="IntaSend">
        </span>
        <hr>
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="duration" class="form-label fw-bold h5 mt-3">Months:</label>
              <select data="" class="form-select form-select-lg" v-model="duration">
                <option value="1" selected>1 Month</option>
                <option value="6">6 Months</option>
                <option value="12">12 Months</option>
              </select>
            </div>
            <div class="form-group col-md-12">
              <label for="price" class="form-label h5 mt-3"><strong>Price:</strong> {{ formatNumber(price) }}</label>
              <hr>
              <div class="float-end">
                <h5 class="text-muted fst-italic">Total: {{ formatNumber(duration * price) }}</h5>
                <h5 class="text-muted fst-italic">Discount: {{ formatNumber(duration === "12" ? price : 0) }}</h5>
              </div>
            </div>
            <div class="form-group col-md-12">
              <label for="total" class="form-label fw-bold h4 mt-3 float-end">Total: {{ formatNumber(amount) }}</label>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="upgradeModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" @click="upgradeNow">Checkout</button>
      </div>
    </Modal>
    <!-- Upgrade Subscription Modal End -->

    <!-- IntaSend Payment Button -->
    <button
        class="intaSendPayButton d-none"
        ref="upgradeBtn"
        data-country="KE"
        :data-amount="amount"
        data-currency="KES"
        :data-email="organization.email"
    ></button>
  </div>

</template>

<script>
import subscriptionService from "@/api/subscriptionService";
import {decrypt} from "@/utils/crypto";
import formatDate from "../../utils/formatDate";
import formatNumber from "../../utils/formatNumber";

export default {
  name: "Upgrade",
  data() {
    return {
      packages: [
        {
          name: '',
          price: 0,
          employees: 0
        }
      ],
      subscription: {
        package: {}
      },
      duration: 1,
      package_id: '',
      package_name: '',
      price: 0,
      amount: 0,
      upgradeModal: false,
      redirectUrl: process.env.VUE_APP_BASE_URL + 'paymentSuccess',
      organization: {},
      loading: true,
      employeesNo: 0
    }
  },

  methods: {
    formatNumber,
    formatDate,
    disableUpgradeBtn(plan) {
      if (this.subscription.package.name === plan.name) {
        return 'd-none'
      } else if (plan.employees <= this.employeesNo) {
        return 'disabled'
      } else {
        return ''
      }
    },
    upgradeNow: async function () {
      let response = await subscriptionService.initPayment({
        organization_id: this.organization.id,
        package_id: this.package_id,
        type: 'upgrade',
        package: this.package_name,
        duration: this.duration,
        amount: this.amount,
      })
      if (response.status === 201) {
        this.upgradeModal = false
        this.$refs.upgradeBtn.setAttribute('data-api_ref', response.data.id)
        this.$refs.upgradeBtn.click()
      }
    },
    selectPackage: function (item) {
      this.package_id = item.id
      this.package_name = item.name
      this.price = item.price
    },
    freeSubscription: async function () {
      await subscriptionService.freeSubscription()
    }
  },

  mounted: async function () {
    new window.IntaSend({
      publicAPIKey: process.env.VUE_APP_INTASEND_API_KEY,
      live: process.env.NODE_ENV !== 'development',
      redirectURL: this.redirectUrl
    })
  },

  async created() {
    let packages = await subscriptionService.getPackages()
    this.loading = false
    this.packages = packages.filter(function(item){ return item['name'] !== 'Trial' && item['name'] !== 'Free' })
    this.organization = JSON.parse(decrypt(sessionStorage.getItem('organization')))
    this.subscription = JSON.parse(decrypt(sessionStorage.getItem('subscription')))
    this.employeesNo = JSON.parse(decrypt(sessionStorage.getItem('employeesNo')))
  },

  watch: {
    duration: function () {
      if (this.duration === "12") {
        this.amount = this.price * 11
      } else {
        this.amount = this.price * this.duration
      }
    },
    price: function () {
      this.amount = this.price * this.duration
    },
  }
}
</script>
