'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"
import userService from "@/api/userService";
import {encrypt} from "@/utils/crypto";
import subscriptionService from "@/api/subscriptionService";
import router from "@/router";
import activityWatcher from "@/utils/activityWatcher";

export default {
  async getOrganization() {
    const data = {
      method: 'get',
      url: 'organization',
    }

    const response = await api.api(data)
    if (response.status === 200) {
      sessionStorage.setItem('organization', encrypt(JSON.stringify(response.data)))
      return response.data
    }
  },
  async getOrganizations() {
    const data = {
      method: 'get',
      url: 'organizations',
    }

    const response = await api.api(data)
    if (response.status === 200) {
      return response.data
    }
  },
  async createOrganization(json) {
    const data = {
      method: 'post',
      url: 'organization',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 201) {
      notify.alert.success('Organization created successfully')
      return true;
    }
  },
  async selfCreateOrganization(json) {
    const data = {
      method: 'post',
      url: 'organization/self',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 201) {
      notify.alert.success('Organization created successfully')
      await userService.getProfile()
      await this.getOrganization()
      await subscriptionService.getSubscription()
      activityWatcher()
      router.push('/dashboard')
    }
  },
  async updateOrganization(json) {
    const data = {
      method: 'patch',
      url: 'organization/' + json.id,
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Organization updated successfully')
      return true;
    }
  },
  async updateOrganizationLogo(json) {
    const data = {
      method: 'patch',
      url: 'organization/update/logo',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Logo updated successfully')
      return true;
    }
  },
  async switchOrganization(id) {
    const data = {
      method: 'post',
      url: 'switch/' + id,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Organization switched successfully')
      sessionStorage.setItem('access_token', encrypt(response.data.token))
      await userService.getProfile()
      await this.getOrganization()
      await subscriptionService.getSubscription()
      window.location.href = '/';
    }
  },

  async uploadOrgCreationFile(json) {
    const data = {
      method: 'post',
      url: 'organization-upload',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Organization created successfully')
      return true;
    }
  },

  async addOrganizationFile(json) {
    const data = {
      method: 'post',
      url: 'organization-file',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Organization file added successfully')
      return true;
    }
  },

  async revokeOrganizationFile(id) {
    const data = {
      method: 'delete',
      url: 'organization-file/' + id,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Organization file revoked successfully')
      window.location.reload()
    }
  },

  async sendMassEmail(json) {
    const data = {
      method: 'post',
      url: 'mass-email',
      body: json,
    }

    const response = await api.api(data)
    if (response.status === 200) {
      notify.alert.success('Mass email sent successfully')
      window.location.reload()
    }
  },
}
