<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5 bg-white">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                Edit Employee
              </span>
            </div>
          </template>
          <div class="col-md-12">
            <form @submit.prevent="">
              <div class="row">
                <div class="form-group col-md-3">
                  <label for="employee_no" class="form-label fw-bold h5 mt-3">Employee No:</label>
                  <input type="text" class="form-control form-control-lg" v-model="employee.employee_no" readonly>
                </div>
                <div class="form-group col-md-3">
                  <label for="first_name" class="form-label fw-bold h5 mt-3">First Name:</label>
                  <input type="text" class="form-control form-control-lg" v-model="employee.first_name">
                </div>
                <div class="form-group col-md-3">
                  <label for="middle_name" class="form-label fw-bold h5 mt-3">Middle Name:</label>
                  <input type="text" class="form-control form-control-lg" v-model="employee.middle_name">
                </div>
                <div class="form-group col-md-3">
                  <label for="last_name" class="form-label fw-bold h5 mt-3">Last Name:</label>
                  <input type="text" class="form-control form-control-lg" v-model="employee.last_name">
                </div>
                <div class="form-group col-md-3">
                  <label for="id_number" class="form-label fw-bold h5 mt-3">ID Number:</label>
                  <input type="text" class="form-control form-control-lg" v-model="employee.id_number">
                </div>
                <div class="form-group col-md-3">
                  <label for="phone" class="form-label fw-bold h5 mt-3">Phone:</label>
                  <input type="text" class="form-control form-control-lg" placeholder="0712333555" v-model="employee.phone">
                </div>
                <div class="form-group col-md-3">
                  <label for="email" class="form-label fw-bold h5 mt-3">Email:</label>
                  <input type="text" class="form-control form-control-lg" v-model="employee.email">
                </div>
                <div class="form-group col-md-3">
                  <label for="gender" class="form-label fw-bold h5 mt-3">Gender:</label>
                  <select data="" type="text" class="form-select form-select-lg" v-model="employee.gender">
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <label for="dob" class="form-label fw-bold h5 mt-3">Date of Birth:</label>
                  <input type="date" class="form-control form-control-lg" v-model="employee.dob">
                </div>
                <div class="form-group col-md-6">
                  <label for="address" class="form-label fw-bold h5 mt-3">Address:</label>
                  <input type="text" class="form-control form-control-lg" v-model="employee.address">
                </div>
                <hr class="border-1 border-info border-opacity-75 mt-5" />
                <div class="form-group col-md-3">
                  <label for="employment_status" class="form-label fw-bold h5 mt-3">Employment Terms:</label>
                  <select data="" type="text" class="form-select form-select-lg" v-model="employee.tos">
                    <option value="Permanent">Permanent</option>
                    <option value="Contract">Contract</option>
                    <option value="Consultant">Consultant</option>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <label for="salary" class="form-label fw-bold h5 mt-3">{{ salaryText }}:</label>
                  <input type="number" class="form-control form-control-lg" v-model="employee.salary">
                </div>
                <div class="form-group col-md-3">
                  <label for="employment_date" class="form-label fw-bold h5 mt-3">Employment Date:</label>
                  <input type="date" class="form-control form-control-lg" v-model="employee.employment_date">
                </div>
                <div v-if="employee.tos !== 'Permanent'" class="form-group col-md-3">
                  <label for="termination_date" class="form-label fw-bold h5 mt-3">Termination Date:</label>
                  <input type="date" class="form-control form-control-lg" v-model="employee.termination_date">
                </div>
                <hr class="border-1 border-info border-opacity-75 mt-2" />
                <div class="form-group col-md-2">
                  <label for="paye" class="form-label fw-bold h6 mt-3">Deduct PAYE:</label>
                  <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="employee.paye">
                </div>
                <div class="form-group col-md-2">
                  <label for="paye" class="form-label fw-bold h6 mt-3">Deduct NHIF:</label>
                  <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="employee.nhif">
                </div>
                <div class="form-group col-md-2">
                  <label for="paye" class="form-label fw-bold h6 mt-3">Deduct NSSF:</label>
                  <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="employee.nssf">
                </div>
                <div class="form-group col-md-2">
                  <label for="paye" class="form-label fw-bold h6 mt-3">Deduct Housing Levy:</label>
                  <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="employee.housing">
                </div>
                <div class="form-group col-md-2">
                  <label for="paye" class="form-label fw-bold h6 mt-3">Deduct NITA:</label>
                  <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="employee.nita">
                </div>
                <div class="form-group col-md-2">
                  <label for="paye" class="form-label fw-bold h6 mt-3">Email Payslip:</label>
                  <input type="checkbox" class="form-control form-control-sm form-check-input" v-model="employee.email_payslip">
                </div>
                <hr class="border-1 border-info border-opacity-75 mt-3" />
                <div class="form-group col-md-4">
                  <label for="department" class="form-label fw-bold h5 mt-3">Department:</label>
                  <select data="" type="text" class="form-select form-select-lg" v-model="employee.department_id">
                    <option v-for="department in departments" :key="department.id" :value="department.id">{{department.name}}</option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <label for="designation" class="form-label fw-bold h5 mt-3">Designation:</label>
                  <select data="" type="text" class="form-select form-select-lg" v-model="employee.designation_id">
                    <option v-for="designation in designations" :key="designation.id" :value="designation.id">{{designation.name}}</option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <label for="work_station" class="form-label fw-bold h5 mt-3">Work Station:</label>
                  <select data="" type="text" class="form-select form-select-lg" v-model="employee.work_station_id">
                    <option v-for="station in stations" :key="station.id" :value="station.id">{{station.name}}</option>
                  </select>
                </div>
                <div class="form-group col-md-4">
                  <label for="pin_no" class="form-label fw-bold h5 mt-3">PIN No:</label>
                  <input type="text" class="form-control form-control-lg" v-model="employee.details.pin_no">
                </div>
                <div class="form-group col-md-4">
                  <label for="nssf_no" class="form-label fw-bold h5 mt-3">NSSF No: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" v-model="employee.details.nssf_no">
                </div>
                <div class="form-group col-md-4">
                  <label for="nhif_no" class="form-label fw-bold h5 mt-3">NHIF No: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" v-model="employee.details.nhif_no">
                </div>
                <div class="form-group col-md-4">
                  <label for="name" class="form-label fw-bold h5 mt-3">Bank Name: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" list="bankOptions" id="bankName" v-model="employee.details.bank_name" placeholder="Type to search...">
                  <datalist id="bankOptions">
                    <option v-for="bank in bankNames.banks" :key="bank" :value="bank"></option>
                  </datalist>
                </div>
                <div class="form-group col-md-4">
                  <label for="name" class="form-label fw-bold h5 mt-3">Branch Name: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" list="branchOptions" id="branchName" v-model="employee.details.branch_name" placeholder="Type to search...">
                  <datalist id="branchOptions">
                    <option v-for="branch in bankBranches" :key="branch" :value="branch"></option>
                  </datalist>
                </div>
                <div class="form-group col-md-4">
                  <label for="name" class="form-label fw-bold h5 mt-3">Account Number: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" placeholder="Account number" v-model="employee.details.account_no">
                </div>
              </div>
            </form>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-4" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
            <button v-if="profile.role !== 'user' && profile.role !== 'admin'" class="btn btn-primary text-uppercase" @click="submitForm">Submit</button>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import employeeService from "@/api/employeeService";
import departmentService from "@/api/departmentService";
import stationService from "@/api/stationService";
import designationService from "@/api/designationService";
import {decrypt} from "@/utils/crypto";
import bankService from "@/api/bankService";

export default {
  name: "EditEmployee",

  data() {
    return {
      profile: {},
      departments: [],
      stations: [],
      designations: [],
      employee: {
        details: {},
      },
      bankNames: {
        banks: [],
        branches: {}
      },
      bankBranches: []
    }
  },

  methods: {
    submitForm: async function () {
      await employeeService.updateEmployee(this.employee, this.$route.params.id)
    },
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    this.employee = await employeeService.getEmployee(this.$route.params.id)
    this.departments = await departmentService.getDepartments()
    this.designations = await designationService.getDesignations()
    this.stations = await stationService.getStations()
    this.employee.paye = !!this.employee.paye
    this.employee.nhif = !!this.employee.nhif
    this.employee.nssf = !!this.employee.nssf
    this.employee.housing = !!this.employee.housing
    this.employee.nita = !!this.employee.nita
    this.employee.email_payslip = !!this.employee.email_payslip
    this.bankNames = await bankService.getBankNames()
    if (this.employee.details.bank_name !== null) {
      this.bankBranches = this.bankNames.branches[this.employee.details.bank_name]
    }
  },

  computed: {
    salaryText: function () {
      if (this.employee.tos === 'Consultant') {
        return 'Salary incl. VAT'
      } else {
        return 'Salary'
      }
    }
  },

  watch: {
    'employee.details.bank_name': function () {
      this.bankBranches = this.bankNames.branches[this.employee.details.bank_name]
    },
    'employee.tos': function () {
      let checked = this.employee.tos !== 'Consultant' && this.employee.tos !== 'Casual';
      document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = checked);
    }
  },
}
</script>

<style scoped>

</style>
