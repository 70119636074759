import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from "./views/Dashboard";
import Default from "@/components/layouts/Default";
import Blank from "@/components/layouts/Blank.vue";
import Login from "@/views/auth/Login.vue";
import Organization from "@/views/organization/Organization.vue";
import notify from "@/utils/notify";
import Employees from "@/views/employee/Employees.vue";
import EmployeeDetails from "@/views/employee/EmployeeDetails.vue";
import Departments from "@/views/department/Departments.vue";
import Designations from "@/views/designation/Designations.vue";
import WorkStations from "@/views/station/WorkStations.vue";
import EmployeePayrolls from "@/views/payroll/EmployeePayrolls.vue";
import PayrollDetails from "@/views/payroll/PayrollDetails.vue";
import Payslip from "@/views/payroll/Payslip.vue";
import Users from "@/views/user/Users.vue";
import AddEmployee from "@/views/employee/AddEmployee.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import UpdatePassword from "@/views/auth/UpdatePassword.vue";
import UserProfile from "@/views/user/UserProfile.vue";
import ChangePassword from "@/views/user/ChangePassword.vue";
import AllOrganizations from "@/views/organization/AllOrganizations.vue";
import CommissionRates from "@/views/commission/CommissionRates.vue";
import EditEmployee from "@/views/employee/EditEmployee.vue";
import VerifyOtp from "@/views/auth/VerifyOtp.vue";
import Settings from "@/views/settings/Settings.vue";
import {decrypt, encrypt} from "@/utils/crypto";
import Upgrade from "@/views/account/Upgrade.vue";
import PaymentSuccess from "@/views/account/PaymentSuccess.vue";
import Billing from "@/views/account/Billing.vue";
import Logs from "@/views/account/Logs.vue";
import SignUp from "@/views/auth/SignUp.vue";
import AddOrganization from "@/views/organization/AddOrganization.vue";
import VerifyPayslip from "@/views/account/VerifyPayslip.vue";
import Templates from "@/views/account/Templates.vue";
import TemplatePreview from "@/views/account/TemplatePreview.vue";
import Integration from "@/views/account/Integration.vue";
import PayrollPayouts from "@/views/payroll/PayrollPayouts.vue";
import Casuals from "@/views/casual/Casuals.vue";
import CasualDetails from "@/views/casual/CasualDetails.vue";
import AddCasual from "@/views/casual/AddCasual.vue";
import EditCasual from "@/views/casual/EditCasual.vue";
import CasualPayrolls from "@/views/payroll/CasualPayrolls.vue";
import CasualPayrollDetails from "@/views/payroll/CasualPayrollDetails.vue";
import CasualPayrollPayouts from "@/views/payroll/CasualPayrollPayouts.vue";


const router = createRouter({
    history: createWebHistory(),
    routes:[
        { path: '/', redirect: '/dashboard' },
        {
            path: '/',
            component: Default,
            children: [
                {
                    path:'/dashboard',
                    component:Dashboard,
                },
                {
                    path:'/organization',
                    component:Organization,
                },
                {
                    path:'/employees',
                    component:Employees,
                },
                {
                    path:'/employee/:id',
                    component:EmployeeDetails,
                },
                {
                    path:'/add-employee',
                    component:AddEmployee,
                },
                {
                    path:'/edit-employee/:id',
                    component:EditEmployee
                },
                {
                    path:'/casuals',
                    component:Casuals,
                },
                {
                    path:'/casual/:id',
                    component:CasualDetails,
                },
                {
                    path:'/add-casual',
                    component:AddCasual,
                },
                {
                    path:'/edit-casual/:id',
                    component:EditCasual
                },
                {
                    path:'/departments',
                    component:Departments,
                },
                {
                    path:'/designations',
                    component:Designations,
                },
                {
                    path:'/work-stations',
                    component:WorkStations,
                },
                {
                    path:'/commission-rates',
                    component:CommissionRates,
                },
                {
                    path:'/payrolls/employees',
                    component:EmployeePayrolls,
                },
                {
                    path:'/payrolls/casuals',
                    component:CasualPayrolls,
                },
                {
                    path:'/payroll/:id',
                    component:PayrollDetails,
                },
                {
                    path:'/payroll/casual/:id',
                    component:CasualPayrollDetails,
                },
                {
                    path:'/payslip/:id',
                    component:Payslip,
                },
                {
                    path:'/upgrade',
                    component:Upgrade,
                },
                {
                    path:'/templates',
                    component:Templates,
                },
                {
                    path:'/billing',
                    component:Billing,
                },
                {
                    path:'/integration',
                    component:Integration,
                },
                {
                    path:'/logs',
                    component:Logs,
                },
                {
                    path:'/users',
                    component:Users,
                },
                {
                    path:'/profile',
                    component:UserProfile,
                },
                {
                    path:'/change-password',
                    component:ChangePassword,
                },
                {
                    path:'/organizations',
                    component:AllOrganizations,
                },
                {
                    path:'/settings',
                    component:Settings,
                },
                {
                    path:'/paymentSuccess',
                    component:PaymentSuccess,
                },
                {
                    path:'/payrollPayouts/:id',
                    component:PayrollPayouts,
                },
                {
                    path:'/casualPayrollPayouts/:id',
                    component:CasualPayrollPayouts,
                },
            ]
        },
        {
            path: '/',
            component: Blank,
            children: [
                {
                    path:'/login',
                    component:Login,
                },
                {
                    path:'/signup',
                    component:SignUp,
                },
                {
                    path:'/verify-otp',
                    component:VerifyOtp
                },
                {
                    path:'/create-organization',
                    component:AddOrganization
                },
                {
                    path:'/forgot-password',
                    component:ForgotPassword,
                },
                {
                    path:'/update-password/:email',
                    component:UpdatePassword,
                },
                {
                    path:'/verifyPayslip/:id',
                    component:VerifyPayslip,
                },
                {
                    path:'/preview/:id',
                    component:TemplatePreview,
                },
            ],
        },
    ],
});

// User access control
let unauthenticated = ["login", "signup", "verify-otp", "forgot-password", "update-password", "verifyPayslip"]
let superAdmin = ["organizations", "settings"]
let expiredSubscription = ["dashboard", "upgrade", "paymentSuccess"]
let neededFiles = ["dashboard", "create-organization", "change-password"]
let emailVerified = ["dashboard", "change-password"];

router.beforeEach(async (to, from, next) => {
    let routeTo = to.path.split('/', 2)[1]
    const token = decrypt(sessionStorage.getItem('access_token'))
    if (!unauthenticated.includes(routeTo)) {
        if (token) {
            let profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
            let subscription = decrypt(sessionStorage.getItem('subscriptionStatus'))
            let isSuperAdmin = profile.role === 'super_admin'
            if (profile.organization_id === null && routeTo !== 'create-organization') {
                notify.alert.info('Please create your organization first.')
                next('/create-organization')
            }
            let orgData = sessionStorage.getItem('organization')
            let organization = orgData !== null ? JSON.parse(decrypt(orgData)) : {files: []}
            if (profile.email_verified_at === null && !emailVerified.includes(routeTo)) {
                notify.alert.info('First time login. Please change your password.')
                next('/change-password')
            } else if (organization.files.length < 2 && !neededFiles.includes(routeTo)) {
                notify.alert.warning('Please upload your organization files first.')
                next('/dashboard')
            } else if (subscription === '' && !expiredSubscription.includes(routeTo) || subscription === 'expired' && !expiredSubscription.includes(routeTo)) {
                notify.alert.warning('You have an expired subscription. Please renew your subscription.')
                next('/dashboard')
            } else if (superAdmin.includes(routeTo) && !isSuperAdmin) {
                notify.alert.warning('You do not have permission to access this page.')
                next('/dashboard')
            }
            next()
        } else {
            next('/login')
        }
    } else {

        if (token) {
            notify.alert.info('You have an active session. Please logout first.')
            next('/')
        }
        next()
    }
})

export default router;
