<template>
  <Doughnut
      id="my-chart-id"
      :options="chartOptions"
      :data="chartData"
  />
</template>

<script>
    import 'chart.js/auto';
    import { Doughnut } from "vue-chartjs";
    import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

    export default {
        name: "DeductionsPieChart",
        components: { Doughnut },

        props:{
            data:{
                type:Object,
                required:true,
            },
        },
      data(){
          let delayed;
          return {
            chartOptions: {
              responsive: true,
              animation: {
                onComplete: () => {
                  delayed = true;
                },
                delay: (context) => {
                  let delay = 0;
                  if (context.type === 'data' && context.mode === 'default' && !delayed) {
                    delay = context.dataIndex * 300 + context.datasetIndex * 100;
                  }
                  return delay;
                },
              },
            },
          }
      },
      computed: {
        chartData() {
          return {
            labels: ['Tax', 'NHIF', 'NSSF', 'Housing Levy', 'NITA'],
            datasets: [
              {
                label: 'Monthly Payroll',
                data: [this.data.tax, this.data.nhif, this.data.nssf, this.data.housing, this.data.nita],
                backgroundColor: ['rgba(247,168,0,0.3)','rgba(251,47,47,0.3)', 'rgba(0,170,255,0.3)', 'rgba(0,179,60,0.3)', 'rgba(0,17,255,0.3)'],
                borderColor: ['rgba(247,168,0,1)','rgba(251,47,47,1)', 'rgba(0,170,255,1)', 'rgba(0,179,60,1)', 'rgba(0,17,255,1)'],
                borderWidth: 2,
                borderRadius: 5,
                borderSkipped: false,
              },
            ]
          }
        }
      },
    }
</script>
